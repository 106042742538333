<template>
    <div class="SupplierEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                ref="supplier"
                label-width="80px"
                :model="supplier"
                @submit.native.prevent
                :rules="rules"
                size="small"
            >
                <el-form-item label="编码" prop="code">
                    <el-input readonly v-model="supplier.code" />
                </el-form-item>
                <el-form-item label="名称" prop="name" :rules="rules.name">
                    <el-input v-model.trim="supplier.name" />
                </el-form-item>
                <el-form-item label="联系人" prop="linkman" :rules="rules.linkman">
                    <el-input v-model.trim="supplier.linkman" />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile" :rules="rules.mobile">
                    <el-input v-model.trim="supplier.mobile" />
                </el-form-item>
                <el-form-item label="地址" prop="address" :rules="rules.address">
                    <el-input v-model.trim="supplier.address" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';

export default {
    name: 'SupplierEdit',
    props: ['form'],

    data() {
        return {
            supplier: {},
            backUp: {
                code: {},
                name: {},
                linkman: {},
                address: {},
                mobile: {},
            },
            rules: {
                name: { required: true, message: '请填写供应商名称', trigger: 'blur' },
                linkman: { required: true, message: '请填写联系人', trigger: 'blur' },
                mobile: mobileValidateRule(),
                address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
            },
        };
    },

    mounted() {
        this.backUp.code = this.form.code;
        this.backUp.name = this.form.name;
        this.backUp.linkman = this.form.linkman;
        this.backUp.address = this.form.address;
        this.backUp.mobile = this.form.mobile;
        this.supplier = JSON.parse(JSON.stringify(this.backUp));
    },
    methods: {
        save() {
            const _this = this;
            this.$refs['supplier'].validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(
                    this,
                    '/goods/supplier/patch_c/' + _this.supplier.code,
                    _this.supplier,
                    null,
                    () => {
                        _this.$message.success('保存成功');
                        this.goBackAndReload();
                    }
                );
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
